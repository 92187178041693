import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import * as Yup from 'yup';

import { useStore } from "../../app/stores/store";

import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { DeleteButtonMuiFormik } from "../../app/common/form/muiFormikComponents/DeleteButtonMuiFormik";
import SubmitButtonMuiFormik from "../../app/common/form/muiFormikComponents/SubmitButtonMuiFormik";
import TextInputMuiFormik from "../../app/common/form/muiFormikComponents/TextInputMuiFormik";
import ConfirmModal from "../../app/common/modals/ConfirmModal";
import InputModal from "../../app/common/modals/InputModal";
import { Organization } from "../../app/models/organization";
import { UserFormValuesDto } from "../../app/models/user";
import { router } from "../../app/router/Routes";

export default observer(function UserDetailsScreen() {

    const { id } = useParams()
    const { accountStore: aS, userManagerStore: uS, orgaStore: oS, modalStore } = useStore();
    const [user, setUser] = useState<UserFormValuesDto>(new UserFormValuesDto());
    const [org, setOrg] = useState<Organization>();

    const isSelfManaging = aS.user?.id === id;

    useEffect(() => {
        id && uS.loadItemFromServer(id).then(item => {
            setUser(new UserFormValuesDto(item));
            setOrg(item?.organization);
        })
    }, [id, oS, uS]);

    const validationSchema = {
        edit: Yup.object({
            email: Yup.string().required('Bitte eine email eingeben'),
            displayName: Yup.string().required('Bitte einen Displaynamen eingeben'),
        }),
        create: Yup.object({
            email: Yup.string().required('Bitte eine email eingeben'),
            displayName: Yup.string().required('Bitte einen Displaynamen eingeben'),
            defaultPassword: Yup.string().required('Bitte ein Passwort vergeben').matches(uS.passwordRequirements, 'Passwort ist zu schwach')
        }),
    }

    async function handleFormSubmit(item: UserFormValuesDto, { resetForm }: any) {
        try {
            if (id) {
                await uS.updateItem(id, item)
                    .then(resetForm({ values: item, dirty: false }))
                    .then(() => toast.success('Gespeichert'))
            } else {
                item.organizationId = oS.selectedItem?.id;
                await uS.createItem(item)
                    .then(resetForm({ values: item, dirty: false }))
                    .then(() => toast.success('Gespeichert'))
            }
            if (!isSelfManaging) router.navigate('/administration')
        } catch (error) {
            console.error(error)
            toast.error('Etwas lief schief: ' + error)
        }
    }

    async function handleDelete(id: string) {
        await uS.deleteItem(id)
        modalStore.closeModal()
        router.navigate('/administration')
    }

    async function handleSetNewPassword(password: string) {
        if (!uS.selectedItem?.id) return

        const success = await uS.setPassword(uS.selectedItem?.id, password)
        if (!success) {
            toast.error(passwordRequirementsNode, { delay: 8000 })
        } else {
            toast.success('Passwort gesetzt')
        }
        modalStore.closeModal()
    }

    return (
        <>
            <Box sx={{ overflow: "auto" }} height="100%" paddingX={10}>
                <Grid container alignContent="flex-start">
                    <Grid item xs={12} paddingY={4} paddingX={2}>
                        <Typography variant="h4">User Details</Typography>
                    </Grid>
                </Grid>

                <Grid item container paddingY={2} paddingX={2} spacing={2} xs={12}>
                    <Formik
                        enableReinitialize
                        validationSchema={id ? validationSchema.edit : validationSchema.create}
                        initialValues={{
                            ...user,
                            orgId: org?.id,
                            orgTitle: org?.title,
                        }}
                        onSubmit={handleFormSubmit}
                    >
                        <Form>
                            <Grid container spacing={2}>
                                <Grid container item justifyContent='stretch'>
                                    <TextInputMuiFormik disabled={!!id} name='email' label="Email" />
                                </Grid>
                                <Grid container item justifyContent='stretch' direction="row" gap={2}>
                                    <Grid item flex={1}>
                                        <TextInputMuiFormik disabled name='orgId' label="Organization ID" />
                                    </Grid>
                                    <Grid item flex={1}>
                                        <TextInputMuiFormik disabled name='orgTitle' label="Organization Title" />
                                    </Grid>
                                </Grid>
                                <Grid container item justifyContent='stretch'>
                                    <TextInputMuiFormik name='displayName' label="Display Name" />
                                </Grid>
                                {!id && <Grid container item justifyContent='stretch'>
                                    <TextInputMuiFormik name='defaultPassword' label="Password" />
                                </Grid>}
                                <Grid container item justifyContent='stretch'>
                                    <TextInputMuiFormik name='bio' multiline rows={4} label="Bio" />
                                </Grid>
                                <Grid item container justifyContent='flex-end' spacing={2}>
                                    {
                                        id &&
                                        <Grid item>
                                            <Tooltip title={passwordRequirementsNode}>
                                                <Button onClick={() => { modalStore.openModal(<InputModal title={"Neues Passwort"} text={""} onConfirm={handleSetNewPassword} onCancel={modalStore.closeModal} />) }}>
                                                    Passwort zurücksetzen
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    }

                                    {
                                        id && !isSelfManaging &&
                                        <Grid item>
                                            <DeleteButtonMuiFormik onClick={() => modalStore.openModal(<ConfirmModal onConfirm={() => { handleDelete(id); }} title={"User löschen?"} text={""} onCancel={modalStore.closeModal} />)} />
                                        </Grid>
                                    }

                                    <Grid item>
                                        <SubmitButtonMuiFormik text="Speichern" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </Grid>
            </Box>
        </>
    );
})

const passwordRequirementsNode = <>
    <h3>Passwortanforderungen:</h3>
    <ul>
        <li>Mindestens eine Ziffer (0-9)</li>
        <li>Mindestens ein Kleinbuchstabe (a-z)</li>
        <li>Mindestens ein Großbuchstabe (A-Z)</li>
        <li>Mindestens ein Sonderzeichen (z.B. !, @, #)</li>
        <li>Mindestens 6 Zeichen lang</li>
    </ul>
</>
