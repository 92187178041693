import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { toSvg } from "html-to-image";
import { getRectOfNodes, getTransformForBounds } from "reactflow";
import sanitize from "sanitize-filename";
import GraphStore from "../../app/stores/graphStore";
import { useStore } from "../../app/stores/store";

function downloadImage(dataUrl: any, fileName: string) {
  fileName = sanitize(fileName);
  const currentDate = new Date();
  const formattedDateStr = `${currentDate.getFullYear()}${currentDate.getMonth() > 10
    ? "0" + currentDate.getMonth()
    : currentDate.getMonth()
    }${currentDate.getDate()}-${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;

  const a = document.createElement("a");
  a.setAttribute("download", `${fileName}-${formattedDateStr}.svg`);
  a.setAttribute("href", dataUrl);
  a.click();
}

const downloadGraphHandler = (graphStore: GraphStore, filename: string) => {
  var reactFlowComponent = document.querySelector(
    ".react-flow__viewport"
  ) as HTMLElement;

  const nodes = graphStore.graph.nodes;

  if (reactFlowComponent == null) return;

  var oldShowIdValue = graphStore.showId;
  graphStore.setShowId(true);

  const nodesBounds = getRectOfNodes(nodes);

  const maxXValue = Math.max(...nodes.map((n) => n.position.x));
  const minXValue = Math.min(...nodes.map((n) => n.position.x));
  const maxYValue = Math.max(...nodes.map((n) => n.position.y));
  const minYValue = Math.min(...nodes.map((n) => n.position.y));
  const width = (maxXValue + Math.abs(minXValue)) * 1.2;
  const height = (maxYValue + Math.abs(minYValue)) * 1.2;

  const transform = getTransformForBounds(nodesBounds, width, height, 0.5, 2);

  toSvg(reactFlowComponent, {
    width: width,
    height: height,
    style: {
      width: width.toString(),
      height: height.toString(),
      transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
    },
    skipFonts: true,
  }).then((dataUrl) => {
    graphStore.setShowId(oldShowIdValue);
    downloadImage(dataUrl, filename);
  });
};

function DownloadButton() {
  const store = useStore();
  const { boardStore, graphStore } = store;

  return (
    <IconButton
      sx={{
        border: 2,
        borderColor: "#E0E0E0",
      }}
      size="large"
      onClick={() => downloadGraphHandler(graphStore, `Cokomo_Graph-${boardStore.selectedBoard?.title}`)}
      focusRipple={false}
    >
      <Download />
    </IconButton>
  );
}

export default DownloadButton;
export {
  downloadGraphHandler
};
