import { ArrowBackIosNew, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Container, Divider, Grid, IconButton, Popover, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { router } from "../router/Routes";

export default observer(function SecondaryScreenWrapper(
    props: {
        startNode?: React.ReactNode
        endNode?: React.ReactNode
    } & React.PropsWithChildren & ActionsDropdownProps
) {
    return (
        <Box height={"100%"}>
            <Grid container direction="column">
                <Grid
                    item
                    container
                    xs={12}
                    borderBottom={1}
                    borderColor="#E0E0E0"
                    padding={2}
                >
                    <Grid
                        item
                        xs={3}
                        justifyContent="start"
                        alignItems="center"
                        display="flex"
                        container
                        spacing={2}
                    >
                        <Grid item>
                            <Button startIcon={<ArrowBackIosNew />} color="inherit" onClick={() => router.navigate(-1)}>
                                <Typography>Zurück</Typography>
                            </Button>
                        </Grid>

                        <Grid item>
                            {props.startNode}
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={6}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        px={4}
                    >
                        <ActionsDropdown {...(props as ActionsDropdownProps)} />
                    </Grid>

                    <Grid
                        container
                        item
                        xs={3}
                        justifyContent="end"
                        alignItems="center"
                        display="flex"
                        spacing={2}
                    >
                        {props.endNode}
                    </Grid>
                </Grid>

                <Grid item container>
                    <Grid item xs={12}>
                        <Container
                            sx={{
                                width: "100%",
                                height: "calc(100vh - 100px)",
                                overflow: "auto",
                            }}
                            maxWidth={false}
                        >
                            {props.children}
                        </Container>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
});

type ActionsDropdownProps = {
    loading?: boolean;
    title?: string;
    items?: Array<
        | {
            label: string;
            icon?: React.ReactNode;
            color?: string;
            onClick?: () => void;
        }
        | undefined
    >;
};

const ActionsDropdown = observer((props: ActionsDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>();

    return (
        <>
            <Box
                ref={containerRef}
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
            >
                {
                    props.title &&
                    <Typography variant="h5" align="center" noWrap>
                        {props.title}
                    </Typography>
                }

                {
                    (props.items?.length ?? 0) > 0 &&
                    <>
                        {!props.loading && (
                            <IconButton onClick={() => setIsOpen(!isOpen)}>
                                {isOpen ? (
                                    <KeyboardArrowUpOutlined />
                                ) : (
                                    <KeyboardArrowDownOutlined />
                                )}
                            </IconButton>
                        )}

                        <Popover
                            open={isOpen}
                            anchorEl={containerRef.current}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            onBlur={() => setIsOpen(false)}
                            PaperProps={{
                                style: {
                                    borderRadius: "20px",
                                    transform: "translate(-50%,20px)",
                                    minWidth: "200px",
                                    maxWidth: "400px",
                                },
                            }}
                        >
                            <Card>
                                <CardContent>
                                    {props.items!.map((item, index) =>
                                        item ? (
                                            <Button
                                                key={`graph_actions_${index}`}
                                                startIcon={item.icon}
                                                fullWidth
                                                sx={{
                                                    justifyContent: "start",
                                                    p: 2,
                                                    borderRadius: "999px",
                                                }}
                                                onClick={item.onClick}
                                                disabled={!item.onClick}
                                                size="small"
                                            >
                                                <Typography
                                                    fontWeight={600}
                                                    color={(!item.onClick && "text.disabled") || item.color || "text.primary"}
                                                >
                                                    {item.label}
                                                </Typography>
                                            </Button>
                                        ) : (
                                            <Divider sx={{ my: 2 }} />
                                        )
                                    )}
                                </CardContent>
                            </Card>
                        </Popover>
                    </>
                }
            </Box>
        </>
    );
}
);
